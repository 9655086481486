<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="작업측정결과(소음제외) 개선목록"
          :columns="notNoiseGrid.columns"
          :gridHeight="gridheight"
          :data="measData.notNoiseImprList"
          :columnSetting="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="false"
                :isImmShow="true"
                ibmTaskTypeCd="ITT0000175"
                ibmTaskUnderTypeCd="ITTU000200"
                @imprChange="imprChange"
              />
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="작업측정결과(소음) 개선목록"
          :columns="noiseGrid.columns"
          :gridHeight="gridheight"
          :data="measData.noiseImprList"
          :columnSetting="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="false"
                :isImmShow="true"
                ibmTaskTypeCd="ITT0000175"
                ibmTaskUnderTypeCd="ITTU000200"
                @imprChange="imprChange"
              />
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="gridImpr"
          title="결과 외 개선 목록"
          :columns="gridImpr.columns"
          :data="gridImpr.data"
          :gridHeight="gridheight2"
          :usePaging="false"
          :filtering="false"
          :columnSetting="false"
          @linkClick="linkClick2"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && !disabled && measData.workMeasurementPlanId" label="LBLIMPRREQUEST" :showLoading="false"  icon="add" @btnClicked="addImpr" />
              <c-btn v-if="editable && !disabled && measData.workMeasurementPlanId" label="즉시조치" :showLoading="false"  icon="add" @btnClicked="addiimImpr" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'work-measure-result-impr',
  props: {
    measData: {
      type: Object,
      default: () => ({
        workMeasurementPlanId: '',
        plantCd: '',
        noiseImprList: [],
        notNoiseImprList: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      colorItems: [],
      gridheight: '',
      gridheight2: '',
      notNoiseGrid: {
        columns: [
          {
            name: 'processCd',
            field: 'processCd',
            label: '공정명',
            align: 'center',
            style: 'width:230px',
            sortable: false,
          },
          {
            name: 'workPlace',
            field: 'workPlace',
            label: '단위작업장소',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'hazardName',
            field: 'hazardName',
            label: '유해인자',
            align: 'center',
            style: 'width:350px',
            sortable: false,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS NO.',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'measDate',
            field: 'measDate',
            label: '측정일자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선/진행상태',
            align: 'center',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
        height: '480px'
      },
      noiseGrid: {
        columns: [
          {
            name: 'processCd',
            field: 'processCd',
            label: '공정명',
            align: 'center',
            style: 'width:230px',
            sortable: false,
          },
          {
            name: 'workPlace',
            field: 'workPlace',
            label: '단위작업장소',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'workContents',
            field: 'workContents',
            label: '작업내용',
            style: 'width:300px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선/진행상태',
            align: 'center',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
        height: '480px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      searchParam: {
        processCd: '',
        processName: '',
        deptCd: '',
        workMeasurementPlanId: '',
        hazardClassFstCd: null,
      },
      impListUrl: '',
      measTime: [],
      editable: false,
      gridImpr: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  computed: {
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.getUrl = selectConfig.sai.workmeas.result.impr.url;
      this.impListUrl = selectConfig.sop.ibm.improve.others.url;

      this.gridheight2 = (window.innerHeight - 708) + 'px';
      this.gridheight = '250px';
      // this.getList();
      this.getImpList();
    },
    getList() {
      this.$http.url = this.getUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        workMeasurementPlanId: this.measData.workMeasurementPlanId
      }
      this.$http.request((_result) => {
        this.notNoiseGrid.data = _result.data;
      },);
    },
    getImpList() {
      if (this.measData.workMeasurementPlanId) {
        this.$http.url = this.impListUrl;
        this.$http.param = {
          ibmTaskTypeCd: 'ITT0000175', 
          ibmTaskUnderTypeCd: 'ITTU000195', 
          relationTableKey: this.measData.workMeasurementPlanId
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridImpr.data = _result.data;
        },);
      }
    },
    closeResultPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    // 개선창 닫기 후
    imprChange() {
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
    linkClick(data, row, num) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = 'LBLIMPROVE';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        if (num == '2') {
          this.popupOptions.closeCallback = this.closeImprPopup2;
        } else {
          this.popupOptions.closeCallback = this.closeImprPopup;
        }
      } else {
        this.popupOptions.title = 'LBLIMMEDIATE';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        if (num == '2') {
          this.popupOptions.closeCallback = this.closeImprPopup2;
        } else {
          this.popupOptions.closeCallback = this.closeImprPopup;
        }
      }
    },
    linkClick2(row) {
      this.linkClick(row, row, '2');
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    closeImprPopup2() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    },
    addImpr() {
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: this.measData.measPlanContent,
        relationTableKey: this.measData.workMeasurementPlanId,
        ibmTaskTypeCd: 'ITT0000175',
        ibmTaskUnderTypeCd: 'ITTU000195', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addiimImpr() {
      this.popupOptions.title = 'LBLIMMEDIATE';
      this.popupOptions.param = {
        requestContents: this.measData.measPlanContent,
        relationTableKey: this.measData.workMeasurementPlanId,
        ibmTaskTypeCd: 'ITT0000175',
        ibmTaskUnderTypeCd: 'ITTU000195', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    }
  }
};
</script>
